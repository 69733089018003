import React, {useEffect, useState} from 'react';
import AppRoutes from "./routes/routes";
import Loading from "./components/Loading/Loading";
import {AuthService} from "./services/AuthService";
import {useDispatch, useSelector, useStore} from "react-redux";

import './App.css';
import AuthAction from "./redux/actions/authActions/actions";

function App() {
  const store = useStore();
  const { token } = useSelector((state: any) => state.auth);
  const dispatch = useDispatch();
  const [loadingInitValues, setLoadingInitValues] = useState(true);

  useEffect(() => {
    verifyUserSession();
  }, [dispatch, store])

  const verifyUserSession = async () => {
    if(token) {
      const verifyUserSessionResponse = await AuthService.verifySession(token)

      if(verifyUserSessionResponse.success) {
        dispatch(AuthAction.signIn(verifyUserSessionResponse.data));
      }else{
        dispatch(AuthAction.signOut({}));
      }
    }else{
      dispatch(AuthAction.signOut({}));
    }

    setLoadingInitValues(false);
  }

  return (
    <>
      { !loadingInitValues ? (
          <AppRoutes />
      ) : (
          <Loading />
      )}
    </>
  );
}

export default App;
