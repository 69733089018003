import axios from "axios";
import {config} from "../../../config/config";

const apiIntegrationsComunidad = axios.create({
    baseURL: config.API_INTEGRACIONES_COMUNIDADTASK_URI,
    headers: {
        "Accept": 'application/json',
        "Content-type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*"
    },
})

export const HomeService = {
    getStatisticFromOrders: async (date: string, carriers: string[] = []) => {
        try {
            const response = await apiIntegrationsComunidad.post(`clients/smu/orders-statistics/${date}`, { carriers });
            return {
                success: true,
                data: {
                    totalOrders: response.data.count,
                    totalFinishedOrders: response.data.ordersDeliveredCount,
                    totalPendingOrders: (response.data.count - response.data.ordersDeliveredCount)
                }
            }
        }catch (err)
        {
            return {
                success: false,
                error: err
            }
        }
    },
}