import axios from "axios";
import {config} from "../../../config/config";


const apiIntegrationsComunidad = axios.create({
    baseURL: config.API_INTEGRACIONES_COMUNIDADTASK_URI,
    headers: {
        "Accept": 'application/json',
        "Content-type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*"
    },
});

const apiIntegrationsLab = axios.create({
    baseURL: 'https://integraciones-labs-v2.kit23.com/api/integrations/v1',
    headers: {
        "Accept": 'application/json',
        "Content-type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        "Authorization": "R4qLIFZLICafC4WQzEwLhBNUvKO"
    },
});

export const SmuPrimeraRutaService = {
    findOrderBySeqId: async (seqId: string) => {
        const moveItemsToEnd = (obj: any) => {
            if (obj.hasOwnProperty('items')) {
                const itemsValue = obj['items'];
                delete obj['items'];
                obj['items'] = itemsValue;
            }
            return obj;
        }

        try {
            const response = await apiIntegrationsComunidad.get(`/clients/smu/order-primera-ruta/${seqId}`);

            return {
                success: true,
                data: moveItemsToEnd(response.data?.order ? response.data.order : response.data)
            }
        }catch (err)
        {
            return {
                success: false,
                error: err
            }
        }
    },
    migrateOrder: async (data: any) => {
        try {
            const response = await apiIntegrationsLab.post(`/primera-ruta/saveOrder`, data);

            return {
                success: true,
                data: response.data
            }
        }catch (err)
        {
            return {
                success: false,
                error: err
            }
        }
    }
}
