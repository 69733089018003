import React, {useState} from 'react';
import AppLayout from "../../components/Layouts/App/App";
import {ProCard} from "@ant-design/pro-components";
import JsonView from "@uiw/react-json-view";
import { githubLightTheme } from '@uiw/react-json-view/githubLight';
import {Alert, Button, Input, message} from 'antd';
import {BranchesOutlined} from "@ant-design/icons";
import {SmuPrimeraRutaService} from "./services/SmuPrimeraRutaService";

const COLLAPSE_NUMBER = 2;
const { Search } = Input;


function SmuPrimeraRuta() {
    const [messageApi, messageContextHolder] = message.useMessage();
    const [searchSeqId, setSearchSeqId] = useState('');
    const [hasError, setHasError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [collapse, setCollapse] = useState(COLLAPSE_NUMBER);
    const [dataSource, setDataSource] = useState<object>({});

    const onSearch = async (value: string) => {
        const searchValue = value.trim();
        setHasError(false);
        setDataSource({});
        setCollapse(COLLAPSE_NUMBER);

        setSearchSeqId('');
        setLoading(true);

        const searchResponse = await SmuPrimeraRutaService.findOrderBySeqId(searchValue);

        setLoading(false);
        setSearchSeqId(searchValue);

        if(searchResponse.success) {
            setDataSource(searchResponse.data);
        }else {
            setHasError(true);
        }
    };

    const migrateOrder = async () => {
        setLoading(true);


        const migrateResponse = await SmuPrimeraRutaService.migrateOrder(dataSource);

        if(migrateResponse.success) {
            messageApi.success((<span>Se migró la orden con seq. ID <b>{searchSeqId}</b> satisfactoriamente.</span>), 3.5);
        }else {
            const error = migrateResponse.error as any;
            const messageErrorPrimeraRuta = error?.response?.data?.data?.message;
            console.log("Error al realizar migración", error)
            messageApi.error( messageErrorPrimeraRuta ? `Api primera ruta: ${messageErrorPrimeraRuta}` : 'Error al realizar migración (Problemas con el servidor de primera ruta)', 3.5);
        }

        setLoading(false);

    }

    return (
        <AppLayout title="Migración a Primera Ruta">
            {messageContextHolder}

            <ProCard
                style={{
                    // minHeight: 350,
                }}
            >
                <div className="ant-pro-table">
                    <div className="ant-pro-card ant-pro-card-border" style={ { padding: '16px 24px' } }>
                        <div style={{ marginBottom: '10px' }}>
                            <Search placeholder="Búsqueda por Seq. ID" onSearch={onSearch} style={{ width: 200 }} disabled={loading}/>
                        </div>

                        {
                            searchSeqId && (
                                <div>
                                    <div style={{ marginBottom: '10px' }}>
                                        {
                                            hasError ? (
                                                <Alert
                                                    message={<span>No se encontró la orden con el seq. ID enviado: <b>{searchSeqId}</b> en Touch.</span>}
                                                    type="error"
                                                    showIcon
                                                    action={
                                                        <Button type="dashed" style={{ visibility: 'hidden' }}>
                                                            Test
                                                        </Button>
                                                    }
                                                />
                                            ) : (
                                                <Alert
                                                    message={<span>Seq ID: <b>{searchSeqId}</b></span>}
                                                    type="info"
                                                    showIcon
                                                    action={
                                                        <Button type="primary" onClick={migrateOrder} loading={loading} icon={<BranchesOutlined />}>
                                                            Migrar orden a primera ruta
                                                        </Button>
                                                    }
                                                />
                                            )
                                        }
                                    </div>


                                    {
                                        !hasError && (
                                            <JsonView value={dataSource} style={githubLightTheme} collapsed={collapse} />
                                        )
                                    }
                                </div>
                            )
                        }

                        {
                            !searchSeqId && (
                                <Alert
                                    message={loading ? 'Buscando, por favor espere ...' : `Debes de buscar una orden por su seq. ID para poder hacer la migración a primera ruta.`}
                                    type="warning"
                                    showIcon
                                    action={
                                        <Button type="dashed" style={{ visibility: 'hidden' }}>
                                            Test
                                        </Button>
                                    }
                                />
                            )
                        }
                    </div>
                </div>
            </ProCard>
        </AppLayout>
    );
}

export default SmuPrimeraRuta;
