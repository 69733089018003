import React from "react";
import {Routes, Route, Navigate } from 'react-router-dom';
import Notfound from '../scenes/NotFound';
import Home from "../scenes/Home";
import Test from "../scenes/Test";
import Login from "../scenes/Login";
import GuestRoute from "./components/GuestRoute";
import PrivateRoute from "./components/PrivateRoute";
import SmuAssignOrder from "../scenes/SmuAssignOrder";
import SmuOrders from "../scenes/SmuOrders";
import Drivers from "../scenes/Drivers";
import Users from "../scenes/Users";
import SmuPrimeraRuta from "../scenes/SmuPrimeraRuta";

export default function AppRoutes() {

    return (
        <Routes>
            <Route path="/" element={<Navigate to='/home'/>} />
            <Route path="/home" element={<PrivateRoute outlet={<Home />} />} />
            {/*<Route path="/smu-orders" element={<PrivateRoute outlet={<SmuOrders />} permissionsRequired={'SHOW_SMU_ORDER_HISTORY_MODULE'} />} />*/}
            {/*<Route path="/smu-assign-order" element={<PrivateRoute outlet={<SmuAssignOrder />} permissionsRequired={'SHOW_SMU_ASSIGN_ORDER_MODULE'}/>} />*/}
            {/*<Route path="/users" element={<PrivateRoute outlet={<Users />} permissionsRequired={'SHOW_SMU_USERS_MAINTENANCE_MODULE'} />}/>*/}
            {/*<Route path="/drivers" element={<PrivateRoute outlet={<Drivers />} permissionsRequired={'SHOW_SMU_DRIVER_MAINTENANCE_MODULE'} />} />*/}
            <Route path="/migracion-primera-ruta" element={<PrivateRoute outlet={<SmuPrimeraRuta />} />} />
            <Route path="/login" element={<GuestRoute outlet={<Login />} />} />
            {/*<Route path="/test" element={<Test />} />*/}
            <Route path="*" element={<Notfound />} />
        </Routes>
    );
}
