import React, { ReactNode, useEffect, useState } from 'react';
import { Dropdown, Spin, ConfigProvider } from 'antd';
import { PageContainer, ProLayout, ProSettings } from "@ant-design/pro-components";
import { LogoutOutlined } from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AuthService } from "../../../services/AuthService";
import AuthAction from "../../../redux/actions/authActions/actions";
import dashboardMenu from "../../../config/dashboardMenu";
import esESIntl from 'antd/es/locale/es_ES';

import './App.css';


const AppLayout = ({ children, title, loading }: { children: React.ReactNode, title?: string | ReactNode, loading?: boolean }): JSX.Element => {
    const dispatch = useDispatch();
    const authUser = useSelector((state: any) => state.auth);
    const navigate = useNavigate();
    const location = useLocation();
    const [localLoading, setLocalLoading] = useState(false);
    const [menu, setMenu] = useState<any>(undefined);

    useEffect(() => {
        setMenu(dashboardMenu(authUser));
    }, []);

    const [settings] = useState<Partial<ProSettings> | undefined>({
        fixSiderbar: true,
        layout: 'mix',
        splitMenus: true,
    });

    const logout = async () => {
        setLocalLoading(true);

        if (authUser.token) {
            await AuthService.logout(authUser.token);
        }

        dispatch(AuthAction.signOut({}));
        setLocalLoading(false);
    }

    return (
        <div
            style={{
                height: '100vh',
            }}
        >
            <ConfigProvider locale={esESIntl}>
                <Spin spinning={(localLoading || !!loading)}>
                    <ProLayout
                        title="TITASK"
                        logo={'./lineas2.png'}
                        {...settings}
                        location={location}
                        actionsRender={(props) => {
                            return []
                        }}
                        route={menu}
                        avatarProps={{
                            src: 'https://gw.alipayobjects.com/zos/antfincdn/efFD%24IOql2/weixintupian_20170331104822.jpg',
                            size: 'small',
                            title: (authUser.id === 0 ? 'Guest' : authUser.name),
                            render: (props, dom) => {
                                return authUser.id !== 0 && (
                                    <Dropdown
                                        menu={{
                                            items: [
                                                {
                                                    key: 'logout',
                                                    icon: <LogoutOutlined />,
                                                    label: 'Logout',
                                                    onClick: () => {
                                                        logout()
                                                    }
                                                },
                                            ],
                                        }}
                                    >
                                        {dom}
                                    </Dropdown>
                                );
                            },
                        }}
                        onMenuHeaderClick={(e) => console.log("to logo link =>", e)}
                        menuItemRender={(item, dom) => (
                            <div
                                onClick={() => navigate(item.path as string)}
                            >
                                {dom}
                            </div>
                        )}
                    >
                        <PageContainer title={title}>
                            {children}
                        </PageContainer>
                    </ProLayout>
                </Spin>
            </ConfigProvider>
        </div>
    );
};

export default AppLayout;